import React from "react";

const PageButton = (props) => {
  return (
    <div className="btn">
      <span className={"hoverBold"}>{props.name}</span>
    </div>
  );
};

export default PageButton;
