import React from "react";

const CurrencyField = (props) => {
  const setChange = (value) => {
    props.onChange(value);
  };
  return (
    <div className="row currencyInput">
      <div className="col-md-6 numberContainer">
        {props.loading ? (
          <div className="spinnerContainer">{props.spinner}</div>
        ) : (
          <input
            className="currencyInputField"
            placeholder="0.0"
            value={props.value}
            onChange={(e) => setChange(e.target.value)}
          />
        )}
      </div>
      <div className="col-md-6 tokenContainer">
        <span className="tokenName">{props.tokenName}</span>
        <div className="balanceContainer">
          <span className="balanceAmount">
            Bal: {props.balance?.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CurrencyField;
