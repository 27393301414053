import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import { MoralisProvider } from "react-moralis";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Swap from "./pages/Swap";
import Stake from "./pages/Stake";
import Mint from "./pages/Mint";
import Header from "./components/Header";
import P2p from "./pages/P2p";
// import { Wallet } from "./components/Wallet";

const App = () => {
  let routes = (
    <Routes>
      <Route exact path="/" element={<Mint />} />
      <Route exact path="/stake" element={<Stake />} />
      <Route exact path="/swap" element={<Swap />} />
      <Route exact path="/p2p" element={<P2p />} />
      {/* <Route exact path="/wallet" element={<Wallet />} /> */}

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );

  return (
    <Router>
      {/* <MoralisProvider initializeOnMount={false}> */}
      <Header />
      {routes}
      <ToastContainer />
      {/* </MoralisProvider> */}
    </Router>
  );
};

export default App;
