import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";

import PageButton from "./PageButton";
import BimdaoLogo from "../img/Bimdao_Logo-512x512.png";

import "../App.css";
import { MY_TOKEN_LIST } from "../utils/TokenList";

const Header = (props) => {
  async function addToken(n) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: MY_TOKEN_LIST[n].address,
            symbol: MY_TOKEN_LIST[n].symbol,
            decimals: MY_TOKEN_LIST[n].decimals,
            image: MY_TOKEN_LIST[n].logoURI,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function addBim(n) {
    await addToken(0);
  }
  async function addBimUsd(n) {
    await addToken(2);
  }
  // async function addBimUah(n) {
  //   await addToken(3);
  // }

  return (
    <div className="Header">
      <div className="appNav ">
        <div className="leftNav m-1">
          <Link to="/">
            <img src={BimdaoLogo} height={"40px"} alt="Bimdao logo" />
          </Link>
        </div>

        <div className="my-2 buttonContainer buttonContainerTop ">
          <Link className=" " to="/mint">
            <PageButton name={"Mint"} />
          </Link>
          <Link className=" " to="/stake">
            <PageButton name={"Stake"} />
          </Link>
          <Link className=" " to="/swap">
            <PageButton name={"Swap"} />
          </Link>
          <Link className=" " to="/p2p">
            <PageButton name={"P2P"} />
          </Link>
        </div>

        <div className="  rightNav">
          <div className=" my-2 buttonContainer ">
            {/* <PageButton name={"..."} isBold={true} /> */}
            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="warning"
                className="rounded-pill"
              >
                ...
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="https://bimdao.io/">About</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item as="button" onClick={addBim}>
                  Add BIM to Wallet
                </Dropdown.Item>
                <Dropdown.Item as="button" onClick={addBimUsd}>
                  Add bimUSD to Wallet
                </Dropdown.Item>
                {/* <Dropdown.Item as="button" onClick={addBimUah}>
                    Add bimUAH to Wallet
                  </Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
