const contractAdressesTest = require("./contractAddresses.json");
const contractAdressesMain = require("./contractAddressesMain.json");
// import contractAdressesMain from "./contractAddressesMain.json";
// import { activeChain } from "./constants.js";
const { activeChain } = require("./constants.js");
console.log(activeChain);
let contractAdresses, contractAdressesPath;
if (activeChain === 421614) {
  contractAdresses = contractAdressesTest;
  contractAdressesPath = "../src/utils/contractAddresses.json";
} else if (activeChain === 42161) {
  contractAdresses = contractAdressesMain;
  contractAdressesPath = "../src/utils/contractAddressesMain.json";
}

//Owner
const ownerAddress = "0x10beFAc6E2D58B5786B75DF9c6ddD5b4FBB1E40E";

//BIM
const bimAddress = contractAdresses.BimDao[0];

//bimUSD
const bimUsdAddress = contractAdresses.BimStable[0];

//USDT
const usdtAddress = contractAdresses.usdtToken[0];

//FundBim
const fundBimAddress = contractAdresses.FundBim[0];

//MintBimUsd
const mintBimUsdAddress = contractAdresses.MintBimUSD[0];

//staking
const stakingAddress = contractAdresses.Staking[0];

//staking
const p2pAddress = contractAdresses.PeerToPeer[0];

//UAH
const bimUahAddress = contractAdresses.BimGryvna[0];

module.exports = {
  contractAdresses,
  contractAdressesPath,
  ownerAddress,
  bimAddress,
  bimUsdAddress,
  usdtAddress,
  bimUahAddress,
  fundBimAddress,
  mintBimUsdAddress,
  stakingAddress,
  p2pAddress,
};
