import React, { useState, useEffect } from "react";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { activeChain } from "../utils/constants";

function ConnectMeta(props) {
  const [hasProvider, setHasProvider] = useState(null);
  const [wallet, setWallet] = useState({ accounts: [] });

  const { getSigner, getWallet, chainId } = props;

  useEffect(() => {
    const refreshAccounts = (accounts) => {
      if (accounts.length > 0) {
        updateWallet(accounts);
      } else {
        // if length 0, user is disconnected
        setWallet({ accounts: [] });
        getWallet({ accounts: [] });
      }
    };

    const getProvider = async () => {
      const provider = await detectEthereumProvider({ silent: true });
      setHasProvider(provider); // transform provider to true or false

      if (provider) {
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        refreshAccounts(accounts);
        window.ethereum.on("accountsChanged", refreshAccounts);
      }
    };

    getProvider();
    return () => {
      window.ethereum?.removeListener("accountsChanged", refreshAccounts);
    };
    // eslint-disable-next-line
  }, []);

  const updateWallet = async (accounts) => {
    setWallet({ accounts });
    const web3provider = new ethers.providers.Web3Provider(window.ethereum);
    getSigner(web3provider);
    getWallet(accounts);
  };

  //switch the chain
  const switchNetwork = async () => {
    if (chainId !== activeChain) {
      let hex_chainId = ethers.utils.hexValue(activeChain);
      // switches network to the one provided

      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hex_chainId }],
      });
      // refresh
      window.location.reload();
    }
  };

  const handleConnect = async () => {
    let accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    updateWallet(accounts);
    switchNetwork();
  };

  const displayAddress =
    wallet.accounts.length > 0
      ? ` ${wallet.accounts[0]?.substring(
          0,
          5
        )}...${wallet.accounts[0]?.substring(37)}`
      : null;

  const walletAddress = wallet.accounts.length > 0 ? displayAddress : "Connect";

  const wrongChain =
    chainId === activeChain ? (
      <button className="btn btn-sm" onClick={handleConnect}>
        {walletAddress}
      </button>
    ) : (
      "Switch to Arbitrum"
    );

  const showIfHasProvider = hasProvider ? wrongChain : "Install Metamask";

  return <div className="">{showIfHasProvider}</div>;
}

export default ConnectMeta;
