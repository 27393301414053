import React from "react";
import { ethers } from "ethers";

const AddModal = (props) => {
  const {
    onClose,
    setTokenSymbol1,
    setTokenSymbol2,
    setTokenQuantity,
    setRate,
    addPosition,
    tokenSymbols,
  } = props;
  const toWei = (ether) => (ether ? ethers.utils.parseEther(ether) : null);

  return (
    <>
      <div className="modal-class" onClick={onClose}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="modal-body">
            <h3 className="titleHeader">Add Position</h3>

            <div className="row">
              <div className="col-md-6 fieldContainer">
                <label htmlFor="token1-select">Token1:</label>
                <select
                  className="select"
                  name="token1"
                  id="token1-select"
                  onChange={(e) => setTokenSymbol1(e.target.value)}
                >
                  <option value="">...token1</option>
                  {tokenSymbols.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                  {/* <option value={"BIM"}>BIM</option>
                  <option value={"bimUSD"}>bimUSD</option>
                  <option value={"bimUSDT"}>bimUSDT</option> */}
                </select>
              </div>

              <div className="col-md-6 fieldContainer">
                <label htmlFor="token2-select">Token2:</label>
                <select
                  className="select"
                  name="token2"
                  id="token2-select"
                  onChange={(e) => setTokenSymbol2(e.target.value)}
                >
                  <option value="">...token2</option>
                  {tokenSymbols.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 fieldContainer">
                <label htmlFor="tokenQty">Q-ty:</label>
                <input
                  id="tokenQty"
                  type="number"
                  className="inputField"
                  placeholder="0.0"
                  onChange={(e) => setTokenQuantity(e.target.value)}
                />
              </div>
              <div className="col-md-6 fieldContainer">
                <label htmlFor="rate">Rate:</label>
                <input
                  id="rate"
                  type="number"
                  className="inputField"
                  placeholder="0.0"
                  onChange={(e) => setRate(toWei(e.target.value))}
                />
              </div>
            </div>
            <div className="row">
              <button
                onClick={() => {
                  addPosition();
                  onClose();
                }}
                className="smallButton hoverButton"
              >
                Add Position
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddModal;
