import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import ConnectButton from "../components/ConnectButton";
import CurrencyField from "../components/CurrencyField";
import ConnectMeta from "../components/ConnectMeta";
import { activeChain } from "../utils/constants";

import {
  getStableContract,
  getStakeBimUSDContract,
} from "../utils/importContracts";

function Stake() {
  const [wallet, setWallet] = useState({ accounts: [] });

  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  // const [signerAddress, setSignerAddress] = useState(undefined);
  const [chainId, setChainId] = useState(undefined);

  const [stableContract, setStableContract] = useState(undefined);
  const [stakeBimUSDContract, setStakeBimUSDContract] = useState(undefined);

  const [totalStaked, setTotalStaked] = useState(0);

  const [stakeAmount, setStakeAmount] = useState(0);
  const [unstakeAmount, setUnstakeAmount] = useState(0);

  const [stableAmount, setStableAmount] = useState(undefined);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewardAmount, setRewardAmount] = useState(0);

  // const [rewardApi, setRewardApi] = useState(12);

  const [stakeToggle, setStakeToggle] = useState(false);

  useEffect(() => {
    //connect the wallet
    const onLoad = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      const chainId = await provider.getNetwork();
      setChainId(chainId.chainId);

      const stableContract = getStableContract();
      setStableContract(stableContract);

      const stakeBimUSDContract = getStakeBimUSDContract();
      setStakeBimUSDContract(stakeBimUSDContract);
    };

    onLoad();
  }, []);

  const toNumber = (hexnumber) => Number(ethers.utils.formatEther(hexnumber));

  //Check if wallet is connected
  const isConnected = () => wallet.accounts.length > 0;

  const getDataFromChain = async () => {
    if (stableContract && stakeBimUSDContract && provider && isConnected()) {
      if (chainId === activeChain) {
        //get bimStable balance
        setStableAmount(
          toNumber(await stableContract.balanceOf(wallet.accounts[0]))
        );

        //get bimStable balance
        setStakedAmount(
          toNumber(await stakeBimUSDContract.getStaked(wallet.accounts[0]))
        );

        //get reward balance
        setRewardAmount(
          toNumber(await stakeBimUSDContract.earned(wallet.accounts[0]))
        );

        //get totalStaked balance
        setTotalStaked(toNumber(await stakeBimUSDContract.s_totalSupply()));
      }
    }
  };
  getDataFromChain();

  //function to get the object to interact with blockchain
  const getSigner = async (provider) => {
    provider.send("eth_requestAccounts", []);
    const signer = await provider.getSigner();
    setSigner(signer);
  };
  const notify = (error) => {
    if (error.toLowerCase().includes("Pausable: paused")) {
      toast.error("The contract is paused!", {
        theme: "colored",
      });
    } else {
      toast.error(error, {
        theme: "colored",
      });
    }
  };

  const onChangeStake = (value) => {
    setStakeAmount(value);
  };

  const onChangeUnstake = (value) => {
    setUnstakeAmount(value);
  };

  const getTx = async (tx) => {
    await tx.wait(1);

    const delayInMilliseconds = 1000 * 5; //1 second
    setTimeout(function () {
      getDataFromChain();
    }, delayInMilliseconds);
  };

  const getTxInfo = async (tx) => {
    toast.promise(getTx(tx), {
      pending: {
        render() {
          return "Pending transaction...";
        },
        icon: false,
      },
      success: {
        render() {
          return `Tx: ${tx.hash}`;
        },
        // other options
        icon: "🟢",
      },
      error: {
        render({ data }) {
          // When the promise reject, data will contains the error
          return `Error: ${data.message}}`;
        },
      },
    });
  };

  const stake = async () => {
    try {
      const bimUsdQuantityWei = ethers.utils.parseEther(stakeAmount);
      await stableContract
        .connect(signer)
        .approve(stakeBimUSDContract.address, bimUsdQuantityWei);
      const tx = await stakeBimUSDContract
        .connect(signer)
        .stake(bimUsdQuantityWei);
      getTxInfo(tx);
    } catch (error) {
      notify(error.message);
    }
  };

  const unstake = async () => {
    const bimUsdQuantityWei = ethers.utils.parseEther(unstakeAmount);
    try {
      const tx = await stakeBimUSDContract
        .connect(signer)
        .withdraw(bimUsdQuantityWei);
      getTxInfo(tx);
    } catch (error) {
      notify(error.message);
    }
  };

  const claimReward = async () => {
    try {
      const tx = await stakeBimUSDContract.connect(signer).claimReward();
      getTxInfo(tx);
    } catch (error) {
      notify(error.message);
      console.log(error);
    }
  };

  const getWallet = (accounts) => {
    setWallet({ accounts });
  };

  return (
    <div className="App">
      <div className="appBody">
        <div className="row">
          <div className="col-md-6">
            {/* wallet connection */}
            <div className="connectContainer">
              <div className="swapHeader ">
                <span className="swapText">Main chain:</span>
                <span className="walletContainer">Arbitrum</span>
              </div>
              <div className="row currencyInput">
                <div className="col-md-4 numberContainer fs-5">Wallet:</div>
                <div className="col-md-8 tokenContainer">
                  <span className="walletContainer">
                    {/* <ConnectWallet
                  getSigner={getSigner}
                  provider={provider}
                  chainId={chainId}
                /> */}
                    <ConnectMeta
                      getWallet={getWallet}
                      getSigner={getSigner}
                      chainId={chainId}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {/* Contract info */}
            <div className="connectContainer">
              <div className="swapHeader ">
                <span className="swapText ">Funds in contract:</span>
              </div>
              <div className="swapHeader currencyInput">
                <span className="swapText">bimUSD</span>
                <span className="walletContainer"> {totalStaked} </span>
              </div>

              <div className="swapHeader currencyInput">
                <span className="swapText ">Average API</span>
                <span className="walletContainer ">{"12"} %</span>
              </div>
            </div>
          </div>
        </div>

        {/* Stake block */}
        {isConnected() && chainId === activeChain ? (
          <div className="row">
            <div className="col-md-6">
              <div className="connectContainer">
                <div className="row currencyInput">
                  <div className="numberContainer">
                    <span className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onChange={() => setStakeToggle(!stakeToggle)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        turn on to Unstake
                      </label>
                    </span>
                  </div>
                </div>

                <div className="swapBody">
                  {stakeToggle === false ? (
                    <>
                      <div className="swapHeader">
                        <span className="swapText">Stake</span>
                      </div>
                      <CurrencyField
                        field="input"
                        tokenName="bimUSD"
                        value={stakeAmount}
                        signer={signer}
                        balance={stableAmount}
                        onChange={onChangeStake}
                      />
                      <div>
                        <ConnectButton name={"Stake"} onClick={stake} />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="swapHeader">
                        <span className="swapText">Unstake</span>
                        <span className="walletContainer">
                          first 48h unstake - 0 reward
                        </span>
                      </div>
                      <CurrencyField
                        field="output"
                        tokenName="bimUSD"
                        value={unstakeAmount}
                        signer={signer}
                        balance={stakedAmount}
                        onChange={onChangeUnstake}
                      />
                      <div>
                        <ConnectButton name={"Unstake"} onClick={unstake} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {stakedAmount || rewardAmount ? (
                <div className="connectContainer">
                  <div className="swapHeader currencyInput">
                    <span className="swapText">Your position:</span>
                    <span className="walletContainer">
                      {stakedAmount?.toFixed(2)} bimUSD
                    </span>
                  </div>

                  <div className="swapHeader currencyInput">
                    <span className="swapText ">Your reward:</span>
                    <span className="walletContainer ">
                      {rewardAmount?.toFixed(2)} BIM
                    </span>
                  </div>
                  <span className="walletContainer">
                    first 48h claim - 0 reward
                  </span>
                  <div>
                    <ConnectButton name={"Get Reward"} onClick={claimReward} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default Stake;
