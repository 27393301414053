import React from "react";

import { Button } from "react-bootstrap";

const ConnectButton = (props) => {
  return (
    <Button
      disabled={props.disabled}
      className="btn my-2 connectButton"
      onClick={props.onClick}
    >
      {props.name}
    </Button>
  );
};

export default ConnectButton;
