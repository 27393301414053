//WEB3 import
import bimDao from "../artifacts/contracts/BimDao.sol/BimDao.json";
import bimStable from "../artifacts/contracts/BimStable.sol/BimStable.json";
// import bimUsdt from "../artifacts/contracts/BimUsdt.sol/BimUsdt.json";
import fundBim from "../artifacts/contracts/FundBim.sol/FundBim.json";
import mintBimUsd from "../artifacts/contracts/MintBimUSD.sol/MintBimUSD.json";
import staking from "../artifacts/contracts/Staking.sol/Staking.json";
import p2p from "../artifacts/contracts/PeerToPeer.sol/PeerToPeer.json";
import {
  bimAddress,
  bimUsdAddress,
  usdtAddress,
  fundBimAddress,
  mintBimUsdAddress,
  stakingAddress,
  p2pAddress,
} from "../utils/addressesToChains";

const { ethers } = require("ethers");
const ERC20ABI = require("../abi.json");

let web3Provider;
if (window.ethereum) {
  web3Provider = new ethers.providers.Web3Provider(window.ethereum);
}

export const getBimContract = () =>
  new ethers.Contract(bimAddress, bimDao.abi, web3Provider);

export const getStableContract = () =>
  new ethers.Contract(bimUsdAddress, bimStable.abi, web3Provider);

export const getUsdtContract = () =>
  new ethers.Contract(usdtAddress, ERC20ABI, web3Provider);

export const getFundBimContract = () =>
  new ethers.Contract(fundBimAddress, fundBim.abi, web3Provider);

export const getMintBimUsdContract = () =>
  new ethers.Contract(mintBimUsdAddress, mintBimUsd.abi, web3Provider);

export const getStakeBimUSDContract = () =>
  new ethers.Contract(stakingAddress, staking.abi, web3Provider);

export const getP2PContract = () =>
  new ethers.Contract(p2pAddress, p2p.abi, web3Provider);
