import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import ConnectMeta from "../components/ConnectMeta";

import { Table } from "../components/Table";
import AddModal from "../components/AddModal.jsx";
import { activeChain } from "../utils/constants";

import {
  getBimContract,
  getP2PContract,
  getStableContract,
  getUsdtContract,
} from "../utils/importContracts";

function P2p() {
  const [wallet, setWallet] = useState({ accounts: [] });

  const [provider, setProvider] = useState(undefined);
  const [signer, setSigner] = useState(undefined);
  const [chainId, setChainId] = useState(undefined);

  const [tokenContracts, setTokenContracts] = useState({});
  const [contract, setContract] = useState(undefined);
  const [tokenSymbols, setTokenSymbols] = useState([]);

  const [showAddModal, setShowAddModal] = useState(false);

  const [tokenSymbol1, setTokenSymbol1] = useState(undefined);
  const [tokenSymbol2, setTokenSymbol2] = useState(undefined);
  const [tokenQuantity, setTokenQuantity] = useState(undefined);
  const [rate, setRate] = useState(undefined);

  const [assets, setAssets] = useState([]);
  const [allPositions, setAllPositions] = useState([]);

  useEffect(() => {
    const onLoad = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      const chainId = await provider.getNetwork();
      setChainId(chainId.chainId);

      const bimContract = getBimContract();
      setTokenContracts((prev) => ({ ...prev, BIM: bimContract }));

      const bimUSDContract = getStableContract();
      setTokenContracts((prev) => ({ ...prev, bimUSD: bimUSDContract }));

      const bimUSDTContract = getUsdtContract();
      setTokenContracts((prev) => ({ ...prev, bimUSDT: bimUSDTContract }));

      const contract = getP2PContract();
      setContract(contract);

      if (chainId.chainId === activeChain) {
        const tokenSymbols = await contract.getTokenSymbols();
        setTokenSymbols(tokenSymbols);
      }
    };
    onLoad();
  }, []);

  const toNumber = (hexnumber) => Number(ethers.utils.formatEther(hexnumber));
  const toEther = (wei) =>
    Number(ethers.utils.formatEther(String(wei))).toFixed(5);
  const toWei = (ether) => ethers.utils.parseEther(ether);

  function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
  }
  //Check if wallet is connected
  const isConnected = () => wallet.accounts.length > 0;

  //function to get the object to interact with blockchain
  const getSigner = async (provider) => {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    setSigner(signer);
  };

  // useEffect(() => {
  //   async function getAddress() {
  //     if (signer) {
  //       const signerAddress = await signer.getAddress();
  //       setSignerAddress(signerAddress);
  //     }
  //   }
  //   getAddress();
  //   return () => {};
  // }, [signer]);

  const getAllPositions = async () => {
    try {
      const allPositionsIdsHex = await contract
        .connect(signer)
        .getPositionIds();

      const allPositionsIds = allPositionsIdsHex.map((id) => Number(id));
      // setAssetIds(assetIds);

      const queriedPositions = await Promise.all(
        allPositionsIds.map((id) =>
          contract.connect(signer).getPositionById(Number(id))
        )
      );
      setAllPositions([]);
      queriedPositions.map(async (asset) => {
        const sellTokenQuantity = toNumber(asset.sellTokenQuantity);
        const buyTokenQuantity = toNumber(asset.buyTokenQuantity);
        const date = new Date(getDate(Number(asset.createdDate)));
        const month = date.getUTCMonth() + 1; //months from 1-12
        const day = date.getUTCDate();
        // const year = dateObj.getUTCFullYear();

        const newdate = day + "/" + month;
        const parsedAsset = {
          positionId: Number(asset.positionId),
          date: newdate,
          sellerAddress: asset.sellerAddress,
          symbol1: asset.sellToken,
          qty1: sellTokenQuantity,
          symbol2: asset.buyToken,
          qty2: buyTokenQuantity,
          rate: toEther(Number(asset.exchangeRate)),
          open: asset.open,
          finished: asset.finished,
        };
        if (
          asset.finished === false &&
          asset.open === true &&
          asset.sellerAddress.toLowerCase() !== wallet.accounts[0].toLowerCase()
        ) {
          setAllPositions((prev) => [...prev, parsedAsset]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMyPositions = async () => {
    try {
      const assetIdsHex = await contract
        .connect(signer)
        .getPositionIdsForAddress();

      const assetIds = assetIdsHex.map((id) => Number(id));
      // setAssetIds(assetIds);

      const queriedAssets = await Promise.all(
        assetIds.map((id) =>
          contract.connect(signer).getPositionById(Number(id))
        )
      );
      setAssets([]);
      queriedAssets.map(async (asset) => {
        const sellTokenQuantity = toNumber(asset.sellTokenQuantity);
        const buyTokenQuantity = toNumber(asset.buyTokenQuantity);
        const date = new Date(getDate(Number(asset.createdDate)));
        const month = date.getUTCMonth() + 1; //months from 1-12
        const day = date.getUTCDate();
        // const year = dateObj.getUTCFullYear();

        const newdate = day + "/" + month;
        const parsedAsset = {
          positionId: Number(asset.positionId),
          date: newdate,
          // sellerAddress: asset.sellerAddress,
          symbol1: asset.sellToken,
          qty1: sellTokenQuantity,
          symbol2: asset.buyToken,
          qty2: buyTokenQuantity,
          rate: toEther(Number(asset.exchangeRate)),
          open: asset.open,
          finished: asset.finished,
        };
        if (asset.finished === false) {
          setAssets((prev) => [...prev, parsedAsset]);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (contract && signer) {
      getMyPositions();
      getAllPositions();
    }
    // eslint-disable-next-line
  }, [contract, signer]);

  const notify = (error) => {
    if (error.toLowerCase().includes("transfer amount exceeds balance")) {
      toast.error("Not enough tokens in smartcontract!", {
        theme: "colored",
      });
    } else {
      toast.error(error, {
        theme: "colored",
      });
    }
  };

  const addPosition = async () => {
    const wei = toWei(tokenQuantity);
    let tokenContract;
    switch (tokenSymbol1) {
      case "bimUSDT":
        tokenContract = tokenContracts.bimUSDT;
        break;

      case "BIM":
        tokenContract = tokenContracts.BIM;
        break;

      case "bimUSD":
        tokenContract = tokenContracts.bimUSD;
        break;

      default:
        break;
    }
    console.log(rate);
    try {
      await tokenContract.connect(signer).approve(contract.address, wei);
      const tx = await contract
        .connect(signer)
        .addPosition(tokenSymbol1, tokenSymbol2, wei, rate);
      await getTxInfo(tx);
    } catch (error) {
      notify(error.message);
      console.log(error);
    }
  };

  const cancelPosition = async (positionId) => {
    try {
      const tx = await contract.connect(signer).cancelPosition(positionId);
      await getTxInfo(tx);
    } catch (error) {
      notify(error.message);
    }
  };

  const getPositionByPositionId = async (positionId) => {
    const position = await contract.connect(signer).getPositionById(positionId);

    return position;
  };

  const takePosition = async (positionId) => {
    const position = await getPositionByPositionId(positionId);
    let tokenContract;
    console.log(position.buyToken);
    switch (position.buyToken) {
      case "bimUSD":
        tokenContract = tokenContracts.bimUSD;
        break;
      case "BIM":
        tokenContract = tokenContracts.BIM;
        break;
      case "bimUSDT":
        tokenContract = tokenContracts.bimUSDT;
        break;

      default:
        break;
    }

    try {
      await tokenContract
        .connect(signer)
        .approve(contract.address, position.sellTokenQuantity);
      const tx = await contract.connect(signer).takePosition(positionId);
      await getTxInfo(tx);
    } catch (error) {
      notify(error.message);
    }
  };

  const withdrawPosition = async (positionId) => {
    try {
      const tx = await contract.connect(signer).withdrawPosition(positionId);
      await getTxInfo(tx);
    } catch (error) {
      notify(error.message);
    }
  };

  const getTx = async (tx) => {
    await tx.wait(1);

    const delayInMilliseconds = 1000 * 5; //1 second
    setTimeout(function () {
      getMyPositions();
      getAllPositions();
    }, delayInMilliseconds);
  };

  const getTxInfo = async (tx) => {
    toast.promise(getTx(tx), {
      pending: {
        render() {
          return "Pending transaction...";
        },
        icon: false,
      },
      success: {
        render() {
          return `Tx: ${tx.hash}`;
        },
        // other options
        icon: "🟢",
      },
      error: {
        render({ data }) {
          // When the promise reject, data will contains the error
          return `Error: ${data.message}}`;
        },
      },
    });
  };

  const getWallet = (accounts) => {
    setWallet({ accounts });
  };
  const allButtonHandler = async (cell) => {
    const positionId = cell.row.original.positionId;
    await takePosition(positionId);
  };
  const allButton = (cell) => {
    return (
      <button
        type="button"
        onClick={() => allButtonHandler(cell)}
        className="smallButton"
      >
        take
      </button>
    );
  };

  const myButtonHandler = async (cell) => {
    const positionId = cell.row.original.positionId;
    const positionOpen = cell.row.original.open;
    const positionFinished = cell.row.original.finished;
    if (!positionOpen && !positionFinished) {
      await withdrawPosition(positionId);
    } else {
      await cancelPosition(positionId);
    }
  };
  const myButton = (cell) => {
    const positionOpen = cell.row.original.open;
    const positionFinished = cell.row.original.finished;
    if (!positionOpen && !positionFinished) {
      return (
        <button
          type="button"
          onClick={() => myButtonHandler(cell)}
          className="smallButtonWithdraw"
        >
          +
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={() => myButtonHandler(cell)}
          className="smallButtonCancel"
        >
          -
        </button>
      );
    }
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  return (
    <>
      {showAddModal && (
        <AddModal
          onClose={() => setShowAddModal(false)}
          setTokenSymbol1={setTokenSymbol1}
          setTokenSymbol2={setTokenSymbol2}
          setTokenQuantity={setTokenQuantity}
          setRate={setRate}
          addPosition={addPosition}
          tokenSymbols={tokenSymbols}
        />
      )}

      <div className="App">
        <div className="appBody">
          <div className="row">
            <div className="col-md-6">
              {/* wallet connection */}
              <div className="yourPosContainer">
                <div className="swapHeader ">
                  <span className="swapText">Main chain:</span>
                  <span className="walletContainer">Arbitrum</span>
                </div>
                <div className="row currencyInput">
                  <div className="col-md-4 numberContainer fs-5">Wallet:</div>
                  <div className="col-md-8 tokenContainer">
                    <span className="walletContainer">
                      <ConnectMeta
                        getSigner={getSigner}
                        chainId={chainId}
                        getWallet={getWallet}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              {/* info block */}
              <div className="  yourPosContainer">
                <div className="currencyInput">
                  <span className="balanceAmount">
                    Add Position with conditions:
                    <br /> Token1, Token2, exchangeRate. It will send seller's
                    Token1 to contract. Seller can cancel Position any time.
                    <br /> Take Position will send buyser's Token2 to contract
                    and release Token1 to buyer.
                    <br /> Withdraw Position will transfer Token2 from contract
                    to seller wallet.
                  </span>
                </div>
                {isConnected() && chainId === activeChain ? (
                  <div className="swapHeader ">
                    <span className="swapText">Add Position:</span>
                    <button
                      className="addButton"
                      onClick={() => openAddModal()}
                    >
                      Add
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {isConnected() &&
          chainId === activeChain &&
          contract &&
          provider &&
          tokenContracts &&
          signer &&
          assets &&
          allPositions ? (
            <div className="row">
              <div className="col-md-6">
                <div className="yourPosContainer">
                  <div className="swapBody">
                    <div className="swapHeader ">
                      <span className="swapText">Your positions:</span>
                      {/* <span className="walletContainer">Search</span> */}
                    </div>
                    <div className="currencyInput">
                      <span className="balanceAmount ">
                        <Table data={assets} buttonHandler={myButton} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="yourPosContainer">
                  <div className="swapHeader">
                    <span className="swapText">All positions:</span>
                    {/* <span className="walletContainer">BIM</span> */}
                  </div>
                  <div className="currencyInput">
                    <span className="balanceAmount">
                      <Table data={allPositions} buttonHandler={allButton} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}
export default P2p;
