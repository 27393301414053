import { useState, useEffect } from "react";
import { lightTheme, SwapWidget } from "@uniswap/widgets";
import { ethers } from "ethers";
import "@uniswap/widgets/fonts.css";

import "../App.css";
import { MY_TOKEN_LIST } from "../utils/TokenList";
import { bimUsdAddress, usdtAddress } from "../utils/addressesToChains";

const myLightTheme = {
  ...lightTheme, // Extend the lightTheme
  accent: "#FDEAF1",
  primary: "#000000",
  secondary: "#565A69",
  container: "#FFFFFF",
  module: "#D9EBF2",
  interactive: "#FBDD62",
};

// const myDarkTheme = {
//   ...darkTheme, // Extend the darkTheme
//   accent: "#2172E5",
//   primary: "#FFFFFF",
//   secondary: "#888D9B",
// };

// if (
//   window.matchMedia &&
//   window.matchMedia("(prefers-color-scheme: dark)").matches
// ) {
//   // dark mode
//   darkMode = true;
// } else {
//   darkMode = false;
// }

const Swap = () => {
  const [provider, setProvider] = useState(undefined);

  useEffect(() => {
    //connect the wallet
    const onLoad = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(provider);

      // const chainId = await provider.getNetwork();
      // setChainId(chainId.chainId);
      // window.location.reload();
    };

    onLoad();
  }, []);

  const jsonRpcUrlMap = {
    42161: [process.env.REACT_APP_INFURA_URL_ARBITRUM],
    421613: [process.env.REACT_APP_INFURA_URL_ARBITRUM_GOERLI],
  };

  return (
    <div className="App">
      <div className="appBody">
        <div className="Uniswap h-100 d-flex align-items-center justify-content-center">
          <SwapWidget
            tokenList={MY_TOKEN_LIST}
            defaultInputTokenAddress={usdtAddress}
            defaultOutputTokenAddress={bimUsdAddress}
            theme={myLightTheme}
            provider={provider}
            jsonRpcUrlMap={jsonRpcUrlMap}
          />
        </div>
      </div>
    </div>
  );
};

export default Swap;
