import { activeChain } from "./constants";
import {
  bimAddress,
  usdtAddress,
  bimUsdAddress,
  bimUahAddress,
} from "../utils/addressesToChains";

export const MY_TOKEN_LIST = [
  {
    name: "bimDao",
    address: bimAddress,
    symbol: "BIM",
    decimals: 18,
    chainId: activeChain,
    logoURI: "https://pin.ski/3Nalrfi",
  },
  {
    name: "Tether USD",
    address: usdtAddress,
    symbol: "USDT",
    decimals: 6,
    chainId: activeChain,
    logoURI: "https://etherscan.io/token/images/tether_32.png",
  },
  {
    name: "bimStable",
    address: bimUsdAddress,
    symbol: "bimUSD",
    decimals: 18,
    chainId: activeChain,
    logoURI: "https://pin.ski/3X4yN1m",
  },
  {
    name: "bimGryvna",
    address: bimUahAddress,
    symbol: "bimUAH",
    decimals: 18,
    chainId: activeChain,
    logoURI: "https://pin.ski/441sjTg",
  },
];
